<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '20vw' }"
      :header="`Adicionar km's | Veículo ${vehicle.registration}`"
      :modal="true"
      :closable="false"
    >
      <form
        name="kms-form"
        class="p-col-12"
        @submit.prevent="save"
        style="min-height: 20vh"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label">
              <InputNumber
                v-model="currentKm"
                :min="
                  vehicle && vehicle.current_kms ? (vehicle.current_kms.km + 1) : 0
                "
                :useGrouping="false"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('new-kms'),
                  },
                  'form-control',
                ]"
                :id="'new-kms'"
                :name="'new-kms'"
              />
              <label for="new-kms">Km's Atuais</label>
            </span>
            <small v-if="errors.has('new-kms')" class="p-error" role="alert">
              Km é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label">
              <Textarea
                v-model="comments"
                :autoResize="true"
                rows="5"
                v-bind:class="['form-control']"
                :id="'new-kms-comments'"
                :name="'new-kms-comments'"
              />
              <label for="new-kms-comments">Comentários</label>
            </span>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="save"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import "vue-loading-overlay/dist/vue-loading.css";
import fleetService from "../../services/fleet.service";
export default {
  name: "KmsForm",
  props: ["show", "vehicle", "origin"],
  components: {},
  watch: {
    show() {
      if (this.show === true) {
        this.currentKm =
          this.vehicle && this.vehicle.current_kms
            ? this.vehicle.current_kms.km
            : 0;
        this.$validator.resume();
        this.comments = null;
      }
    },
  },
  data() {
    return {
      currentKm: null,
      comments: null,
    };
  },
  async created() {},
  methods: {
    cancel() {
      this.$validator.pause();
      this.comments = null;
      this.currentKm = null;
      return this.$emit("cancel");
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        this.invalidConductor = false;
        if (!isValid) {
          this.loading = false;
          return;
        }

         let bodyParams = {
          kms: this.currentKm,
          origin: this.origin,
          comments: this.comments,
         };
        this.$validator.pause();
        fleetService
        .addKm(this.vehicle.id,bodyParams)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: `Erro ao adicionar os kms`,
              detail: "",
              life: 3000,
            });
          }
          this.$toast.add({
            severity: "success",
            summary: `Kms Atualizado com sucesso`,
            detail: "",
            life: 3000,
          });
          return this.$emit("addedKm", {
            status: "ok",
            currents_kms: response,
          });
        });
      });
    },
  },
};
</script>
