<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '50vw' }"
      :header="insurance.id ? 'Editar Seguro' : 'Adicionar Seguro'"
      :modal="true"
      :closable="false"
    >
      <form
        name="InsuranceForm"
        class="p-col-12"
        @submit.prevent="save"
        style="min-height: 60vh"
      >
        <div class="p-fluid p-formgrid p-grid" style="min-height: 20vh">
          <div class="p-field p-col-12 p-md-3">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <v-date-picker
                  :name="'insurance_start_picker'"
                  :id="'insurance_start_picker'"
                  v-model="insurance.start"
                  v-validate="'required'"
                  :masks="{
                    input: 'YYYY-MM-DD',
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        :name="'insurance_start'"
                        :id="'insurance_start'"
                        v-bind:class="[
                          {
                            'p-error': errors.has('insurance_start'),
                          },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="inputEvents"
                        v-validate="'required'"
                      />
                      <label for="insurance_start"
                        >Data de Inicío do Seguro</label
                      >
                    </span>
                  </template>
                </v-date-picker>
              </span>
            </div>
            <small
              v-if="errors.has('insurance_start')"
              class="p-error p-ml-5"
              role="alert"
            >
              Data de Inicío do Seguro é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-3">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <v-date-picker
                  :name="'insurance_end_picker'"
                  :id="'insurance_end_picker'"
                  v-model="insurance.end"
                  v-validate="'required'"
                  :masks="{
                    input: 'YYYY-MM-DD',
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        :name="'insurance_end'"
                        :id="'insurance_end'"
                        v-bind:class="[
                          {
                            'p-error': errors.has('insurance_end'),
                          },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="inputEvents"
                        v-validate="'required'"
                      />
                      <label for="insurance_end">Data de Fim do Seguro</label>
                    </span>
                  </template>
                </v-date-picker>
              </span>
            </div>
            <small
              v-if="errors.has('insurance_end')"
              class="p-error p-ml-5"
              role="alert"
            >
              Data de Fim do Seguro é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-3">
            <span class="p-float-label">
              <Dropdown
                v-model="insurance.duration"
                :options="durations"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('insurance-duration'),
                  },
                  'form-control',
                ]"
                :name="'insurance-duration'"
                :id="'insurance-duration'"
              />
              <label for="insurance-duration">Duração</label>
            </span>
            <small
              v-if="errors.has('insurance-duration')"
              class="p-error"
              role="alert"
            >
              Duração é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <span class="p-float-label">
              <AutoComplete
                :suggestions="filteredInsureres"
                @complete="searchInsureres($event)"
                v-model="insurance.insurer"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('insurer'),
                  },
                  'form-control',
                ]"
                :id="'insurer'"
                :name="'insurer'"
              />
              <label for="insurer">Seguradora</label>
            </span>
            <small v-if="errors.has('insurer')" class="p-error" role="alert">
              Seguradora é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-4 p-mt-2">
            <span class="p-float-label">
              <InputText
                type="text"
                v-model="insurance.insurance"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('insurance'),
                  },
                  'form-control',
                ]"
                :id="'insurance'"
                :name="'insurance'"
              />
              <label for="insurance">Apólice</label>
            </span>
            <small v-if="errors.has('insurance')" class="p-error" role="alert">
              Apólice é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-2 p-mt-2">
            <span class="p-float-label">
              <InputNumber
                v-model="insurance.value"
                mode="currency"
                currency="EUR"
                locale="pt-PT"
                v-bind:class="['form-control']"
                :id="'value'"
                :name="'value'"
              />
              <label for="value">Valor</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-2">
            <span class="p-float-label">
              <Dropdown
                v-model="insurance.type"
                :options="types"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('type'),
                  },
                  'form-control',
                ]"
                :name="'type'"
                :id="'type'"
              />
              <label for="type">Tipo</label>
            </span>
            <small v-if="errors.has('type')" class="p-error" role="alert">
              Tipo é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-2 p-mt-2">
            <span class="p-float-label">
              <InputNumber
                v-model="insurance.frank"
                mode="currency"
                currency="EUR"
                locale="pt-PT"
                v-bind:class="['form-control']"
                :id="'frank'"
                :name="'frank'"
                v-validate="
                  insurance.type == 'Todos os Riscos' ? 'required' : ''
                "
                :disabled="insurance.type != 'Todos os Riscos'"
              />
              <label for="frank">Franquia</label>
            </span>
            <small v-if="errors.has('frank')" class="p-error" role="alert">
              Franquia é obrigatória
            </small>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label">
              <Textarea
                v-model="insurance.comments"
                :autoResize="true"
                rows="5"
                v-bind:class="['form-control']"
                :id="'comments'"
                :name="'comments'"
              />
              <label for="comments">Comentários</label>
            </span>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="save"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import "vue-loading-overlay/dist/vue-loading.css";
import fleetService from "../../services/fleet.service";
import { getOnlyDate } from "../../helpers/helpers";
export default {
  name: "InsuranceForm",
  props: ["show", "insurance", "vehicleId"],
  components: {},
  watch: {
    show() {
      if (this.show === true) {
        this.$validator.resume();
      }
    },
  },
  data() {
    return {
      loading: true,
      durations: [],
      types: [],
      insureresList: [],
      filteredInsureres: null,
    };
  },
  async created() {
    this.durations = await fleetService.getInsuranceDuration();
    this.types = await fleetService.getInsuranceTypes();
    this.insureresList = await fleetService.getInsureres();
    this.loading = false;
  },
  methods: {
    searchInsureres(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredInsureres = [...this.insureresList];
        } else {
          this.filteredInsureres = this.insureresList.filter((brand) => {
            return brand.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    cancel() {
      this.$validator.pause();
      return this.$emit("cancel");
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        if (this.filteredInsureres && this.filteredInsureres.length == 0) {
          this.insureresList.push(this.insurance.insurer);
        }
        if (this.insurance.id) {
          return this.updateInsurance();
        }
        return this.createInsurance();
      });
    },
    updateInsurance() {
      let params = {
        insurance: this.insurance.insurance,
        insurer: this.insurance.insurer,
        duration: this.insurance.duration,
        type: this.insurance.type,
        value: this.insurance.value,
        frank: this.insurance.frank,
        start:
          this.insurance.start instanceof Date
            ? getOnlyDate(this.insurance.start)
            : this.insurance.start,
        end:
          this.insurance.end instanceof Date
            ? getOnlyDate(this.insurance.end)
            : this.insurance.end,
        comments: this.insurance.comments,
      };
      fleetService
        .updateInsurance(this.insurance.vehicle_id, this.insurance.id, params)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: `Erro ao Atualizar o Seguro`,
              detail: "",
              life: 3000,
            });
          }
          this.$toast.add({
            severity: "success",
            summary: `Seguro Atualizado com sucesso`,
            detail: "",
            life: 3000,
          });
          return this.$emit("insuranceUpdateOk", {
            status: "ok",
            insurance: response,
          });
        });
    },
    createInsurance() {
      let params = {
        insurance: this.insurance.insurance,
        insurer: this.insurance.insurer,
        duration: this.insurance.duration,
        type: this.insurance.type,
        start:
          this.insurance.start instanceof Date
            ? getOnlyDate(this.insurance.start)
            : this.insurance.start,
        end:
          this.insurance.end instanceof Date
            ? getOnlyDate(this.insurance.end)
            : this.insurance.end,
        comments: this.insurance.comments,
      };
      fleetService.createInsurance(this.vehicleId, params).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: `Erro ao Atualizar o Seguro`,
            detail: "",
            life: 3000,
          });
        }
        this.$toast.add({
          severity: "success",
          summary: `Seguro Atualizado com sucesso`,
          detail: "",
          life: 3000,
        });
        return this.$emit("insuranceCreatedOk", {
          status: "ok",
          insurance: response,
        });
      });
    },
  },
};
</script>
