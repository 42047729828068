<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="dialogSendFleetFile" />
  </div>
</template>
<script>
import fleetService from "../../services/fleet.service";
export default {
  name: "FleetSendFile",
  props: ["show", "origin", "docId", "vehicleId", "title", "doc", "conductor"],
  watch: {
    show() {
      if (this.show === true) {
        this.removeFleetFile();
      }
    },
  },
  methods: {
    removeFleetFile() {
      let title = `Enviar Ficheiro por Email`;
      title += ` | ${this.title}`;
      let message = `Tem a certeza que pretende enviar o ficheiro ${this.doc.file_origin_name}, para ${this.conductor}?`;
      this.$confirm.require({
        key: "dialogSendFleetFile",
        header: title,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return this.sendFileToEmail();
        },
        reject: () => {
          return this.$emit("cancel");
        },
      });
    },
    sendFileToEmail() {
      let params = {
        vehicle_id: this.vehicleId,
        doc_id: this.docId,
      }
      return fleetService
        .sendDocument(params)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao enviar o documento",
              life: 3000,
            });
          }
          this.$toast.add({
              severity: "success",
              summary: "Documento Enviado",
              detail: "O Documento enviado com sucesso",
              life: 3000,
            });
            return this.$emit("fileSended", {
              origin: this.origin,
              docId: this.docId
            });
        });
    },
  },
};
</script>
