<template>
  <div :style="{ position: 'relative' }">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '50vw' }"
      :header="fineId != null ? `Ver Multa` : `Adicionar Multa`"
      :modal="true"
      :closable="false"
    >
      <Loading
        :active.sync="loading"
        :isFullPage="false"
        :zIndex="9000"
      ></Loading>
      <p>
        <b>{{ title }}</b>
      </p>
      <Message
        severity="info"
        v-if="editable && conductorSugestion"
        :closable="false"
        ><b>Condutor neste dia: </b> {{ conductorSugestion.username }}</Message
      >
      <form
        name="vehicle-fine-form"
        class="p-col-12"
        @submit.prevent="save"
        style="min-height: 50vh"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-4">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <v-date-picker
                  :disabled="!editable"
                  v-model="date"
                  :name="'fine_date_picker'"
                  :id="'fine_date_picker'"
                  v-validate="'required'"
                  :max-date="new Date()"
                  :masks="{
                    input: 'YYYY-MM-DD',
                  }"
                  mode="dateTime"
                  is24hr
                  @input="getCondutor"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        :disabled="!editable"
                        :name="'fine_date'"
                        :id="'fine_date'"
                        v-bind:class="[
                          {
                            'p-error': errors.has('fine_date'),
                          },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="editable ? inputEvents : null"
                        v-validate="'required'"
                      />
                      <label for="fine_date">Data da Multa</label>
                    </span>
                  </template>
                </v-date-picker>
              </span>
            </div>
            <small
              v-if="errors.has('fine_date')"
              class="p-error p-ml-5"
              role="alert"
            >
              Data da Multa é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <v-date-picker
                  :disabled="!editable"
                  v-model="dateNotification"
                  :name="'fine_date_notification_picker'"
                  :id="'fine_date_notification_picker'"
                  v-validate="'required'"
                  :max-date="new Date()"
                  :masks="{
                    input: 'YYYY-MM-DD',
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        :disabled="!editable"
                        :name="'fine_date_notification'"
                        :id="'fine_date_notification'"
                        v-bind:class="[
                          {
                            'p-error': errors.has('fine_date_notification'),
                          },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="editable ? inputEvents : null"
                        v-validate="'required'"
                      />
                      <label for="fine_date_notification"
                        >Data da Notificação</label
                      >
                    </span>
                  </template>
                </v-date-picker>
              </span>
            </div>
            <small
              v-if="errors.has('fine_date_notification')"
              class="p-error p-ml-5"
              role="alert"
            >
              Data da Notificação é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <v-date-picker
                  :disabled="!editable"
                  v-model="dateResponse"
                  :name="'fine_date_response_picker'"
                  :id="'fine_date_response_picker'"
                  v-validate="'required'"
                  :masks="{
                    input: 'YYYY-MM-DD',
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        :disabled="!editable"
                        :name="'fine_date_response'"
                        :id="'fine_date_response'"
                        v-bind:class="[
                          {
                            'p-error': errors.has('fine_date_response'),
                          },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="editable ? inputEvents : null"
                        v-validate="'required'"
                      />
                      <label for="fine_date_response"
                        >Data Limite Resposta</label
                      >
                    </span>
                  </template>
                </v-date-picker>
              </span>
            </div>
            <small
              v-if="errors.has('fine_date_response')"
              class="p-error p-ml-5"
              role="alert"
            >
              Data Limite da Resposta é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-2">
            <span class="p-float-label">
              <AutoComplete
                :disabled="!editable"
                :suggestions="filteredTypes"
                @complete="searchTypes($event)"
                v-model="type"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('type'),
                  },
                  'form-control',
                ]"
                :id="'type'"
                :name="'type'"
              />
              <label for="type">Tipo de Multa</label>
            </span>
            <small v-if="errors.has('type')" class="p-error" role="alert">
              Tipo de Multa é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-2">
            <span class="p-float-label">
              <AutoComplete
                :disabled="!editable"
                :suggestions="filteredEmployees"
                :field="'username'"
                @complete="searchEmployee($event)"
                v-model="conductor"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('conductor'),
                  },
                  'form-control',
                ]"
                :id="'conductor'"
                :name="'conductor'"
              />
              <label for="conductor">Condutor</label>
            </span>
            <small v-if="errors.has('conductor')" class="p-error" role="alert">
              Condutor é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-4 p-mt-2">
            <span class="p-float-label">
              <Textarea
                :disabled="!editable"
                v-model="comment"
                :autoResize="true"
                rows="1"
                v-bind:class="['form-control']"
                :id="'comment'"
                :name="'comment'"
              />
              <label for="conductor">Comentário</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-2 p-mt-2" v-if="editable">
            <span
              class="p-float-label"
              style="top: -0.6rem; left: -1rem; font-size: 12px"
            >
              <label for="sendEmail">Enviar por Email</label>
            </span>
            <InputSwitch
              v-model="sendEmail"
              :name="'sendEmail'"
              :id="'sendEmail'"
              style="'top: 3px;"
            />
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <DataTable
              v-if="fineId"
              :value="documentsList"
              filterDisplay="row"
              :loading="loading"
              class="p-datatable-sm"
              :sortOrder="1"
              responsiveLayout="scroll"
              removableSort
              sortMode="single"
              :autoLayout="true"
            >
              <template #header>
                <div class="table-header">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    <span class="table-title">Documentos</span>
                  </div>
                </div>
              </template>
              <template #empty> Não existem Documentos. </template>
              <template #loading> A carregar. Por favor Aguarde... </template>
              <Column
                field="file_origin_name"
                filterField="file_origin_name"
                header="Ficheiro"
                filterMatchMode="contains"
              >
                <template #body="slotProps">
                  <span
                    class="clickable-item"
                    @click="downloadFile(slotProps.data)"
                    >{{ slotProps.data.file_origin_name }}</span
                  >
                </template>
              </Column>
              <Column
                field="document_type"
                filterField="document_type"
                header="Tipo"
                filterMatchMode="contains"
              >
                <template #body="slotProps">
                  <span
                    v-if="slotProps.data.document_type == 'notificationFile'"
                  >
                    Notificação
                  </span>
                  <span
                    v-else-if="
                      slotProps.data.document_type ==
                      'conductorNotificationFile'
                    "
                  >
                    Condutor Notificação
                  </span>
                  <span
                    v-else-if="slotProps.data.document_type == 'responseFile'"
                  >
                    Resposta
                  </span>
                </template>
              </Column>
              <Column
                field="created_at"
                filterField="created_at"
                header="Data"
                sortable
              >
              </Column>
            </DataTable>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-2 p-grid" v-if="editable">
            <FileUpload
              ref="notificationFile"
              name="notificationFile.files[]"
              id="notificationFile"
              chooseLabel="Ficheiro da Notificação"
              :previewWidth="100"
              :multiple="false"
              :showUploadButton="false"
              :showCancelButton="false"
              mode="basic"
              class="p-mr-1"
            />
            <FileUpload
              ref="conductorNotificationFile"
              name="conductorNotificationFile.files[]"
              id="conductorNotificationFile"
              chooseLabel="Ficheiro da Notificação ao Condutor"
              :previewWidth="100"
              :multiple="false"
              :showUploadButton="false"
              :showCancelButton="false"
              mode="basic"
              class="p-mr-1"
            />
            <FileUpload
              ref="responseFile"
              name="responseFile.files[]"
              id="responseFile"
              chooseLabel="Ficheiro Resposta"
              :previewWidth="100"
              :multiple="false"
              :showUploadButton="false"
              :showCancelButton="false"
              mode="basic"
            />
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          v-if="editable"
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="save"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import fleetService from "../../services/fleet.service";
import employeeService from "../../services/employee.service";
import { getOnlyDate, getOnlyTime } from "../../helpers/helpers";
export default {
  name: "FineForm",
  props: ["show", "vehicleId", "fineId", "title"],
  components: { Loading },
  watch: {
    async show() {
      if (this.show === true) {
        this.$validator.resume();
        this.reset();
        await this.getTypes();
        if (this.fineId) {
          await this.getFineDetail();
        }
      }
    },
  },
  data() {
    return {
      loading: false,
      typesList: [],
      employeeList: [],
      documentsList: null,
      date: null,
      dateNotification: null,
      dateResponse: null,
      conductor: null,
      type: null,
      comment: null,
      filteredTypes: null,
      filteredEmployees: null,
      conductorSugestion: null,
      editable: false,
      sendEmail: false,
    };
  },
  async created() {
    this.loading = true;
    this.employeeList = await employeeService.getLastYear();
    this.loading = false;
    this.editable = this.can("editVehicleFines") ? true : false;
  },
  methods: {
    reset() {
      this.date = null;
      this.dateNotification = null;
      this.dateResponse = null;
      this.conductor = null;
      this.type = null;
      this.comment = null;
      this.filteredTypes = null;
      this.conductorSugestion = null;
      this.documentsList = null;
      this.sendEmail = false;
    },
    getTypes() {
      this.loading = true;
      return fleetService.getFinesTpes().then((response) => {
        this.typesList = response;
        return (this.loading = false);
      });
    },
    searchTypes(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredTypes = [...this.typesList];
        } else {
          this.filteredTypes = this.typesList.filter((type) => {
            return type.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchEmployee(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredEmployees = [...this.employeeList];
        } else {
          this.filteredEmployees = this.employeeList.filter((employee) => {
            return employee.username
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    getCondutor() {
      let date = `${getOnlyDate(this.date)} ${getOnlyTime(this.date)}`;
      return fleetService
        .searchCondutorByVehicleDate(this.vehicleId, date)
        .then((response) => {
          if (this.fineId == undefined || this.fineId == null) {
            this.conductor = response;
          }
          this.conductorSugestion = response;
        });
    },
    getFineDetail() {
      this.loading = true;
      return fleetService.getFine(this.fineId).then((response) => {
        this.date = new Date(response.date);
        this.dateNotification = new Date(response.date_notification);
        this.dateResponse = new Date(response.date_to_response);
        this.conductor = response.conductor;
        this.type = response.type;
        this.comment = response.comment == "null" ? "" : response.comment;
        this.documentsList = response.documents;
        return (this.loading = false);
      });
    },
    downloadFile(fileInfo) {
      return fleetService
        .getDocument(fileInfo.vehicle_id, fileInfo.id)
        .then((response) => {
          let url = window.URL.createObjectURL(new Blob([response]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileInfo.file_origin_name);
          document.body.appendChild(link);
          link.click();
        });
    },
    cancel() {
      this.$validator.pause();
      this.reset();
      return this.$emit("cancel");
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return (this.loading = false);
        }
        this.$validator.pause();
        let formData = new FormData();
        formData.append(
          "date",
          `${getOnlyDate(this.date)} ${getOnlyTime(this.date)}`
        );
        formData.append(
          "date_notification",
          getOnlyDate(this.dateNotification)
        );
        formData.append("date_to_response", getOnlyDate(this.dateResponse));
        formData.append("employee_id", parseInt(this.conductor.employeeId));
        formData.append("vehicle_id", parseInt(this.vehicleId));
        formData.append("type", this.type);
        formData.append("comment", this.comment);
        formData.append("send_email", this.sendEmail ? 1 : 0);
        if (this.$refs.notificationFile.files.length == 1) {
          formData.append(
            "notificationFile",
            this.$refs.notificationFile.files[0]
          );
        }
        if (this.$refs.conductorNotificationFile.files.length == 1) {
          formData.append(
            "conductorNotificationFile",
            this.$refs.conductorNotificationFile.files[0]
          );
        }
        if (this.$refs.responseFile.files.length == 1) {
          formData.append("responseFile", this.$refs.responseFile.files[0]);
        }

        if (this.fineId == undefined || this.fineId == null) {
          return this.createFine(formData);
        }
        return this.updateFine(formData);
      });
    },
    createFine(formData) {
      this.loading = true;
      return fleetService.createFine(formData).then((response) => {
        this.loading = false;
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: `Erro ao Adicionar Multa`,
            detail: "",
            life: 3000,
          });
        }

        this.$toast.add({
          severity: "success",
          summary: "Multa Criada",
          detail: "A multa foi criada com sucesso",
          life: 3000,
        });

        return this.$emit("savedFine", {
          status: "ok",
          fine: response,
        });
      });
    },
    updateFine(formData) {
      this.loading = true;
      return fleetService.updateFine(this.fineId, formData).then((response) => {
        this.loading = false;
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: `Erro ao Atualizar Multa`,
            detail: "",
            life: 3000,
          });
        }
        this.$toast.add({
          severity: "success",
          summary: "Multa Atualizada",
          detail: "A multa foi atualizada com sucesso",
          life: 3000,
        });

        return this.$emit("savedFine", {
          status: "ok",
          fine: response,
        });
      });
    },
  },
};
</script>
