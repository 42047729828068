<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="dialogRemoveFleetFile" />
  </div>
</template>
<script>
import fleetService from "../../services/fleet.service";
export default {
  name: "FleetRemoveFile",
  props: ["show", "origin", "docId", "vehicleId", "title", "doc"],
  watch: {
    show() {
      if (this.show === true) {
        this.removeFleetFile();
      }
    },
  },
  methods: {
    removeFleetFile() {
      let title = `Remover Ficheiro`;
      title += ` | ${this.title}`;
      let message = `Tem a certeza que pretende remover o ficheiro ${this.doc.file_origin_name}?`;
      this.$confirm.require({
        key: "dialogRemoveFleetFile",
        header: title,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return this.removeFile();
        },
        reject: () => {
          return this.$emit("cancel");
        },
      });
    },
    removeFile() {
      return fleetService
        .deleteDocument(this.vehicleId, this.docId)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao eliminar o documento",
              life: 3000,
            });
          }
          this.$toast.add({
              severity: "success",
              summary: "Documento eliminado",
              detail: "O Documento eliminado com sucesso",
              life: 3000,
            });
            return this.$emit("fileDeleted", {
              origin: this.origin,
              docId: this.docId
            });
        });
    },
  },
};
</script>
