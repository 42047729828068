<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="`Adicionar Ficheiro`"
      :modal="true"
      :closable="false"
    >
    <p><b>{{ title }}</b></p>
      <form name="add-file-form" class="p-col-12 p-mt-4" @submit.prevent="save">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-8">
            <span class="p-float-label">
              <InputText
                type="text"
                v-model="docType"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('fleetUploadType'),
                  },
                  'form-control',
                ]"
                :id="'fleetUploadType'"
                :name="'fleetUploadType'"
              />
              <label for="fleetUploadType">Tipo do Documento</label>
            </span>
            <small
              v-if="errors.has('fleetUploadType')"
              class="p-error"
              role="alert"
            >
              Tipo do Documento é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <span
              class="p-float-label"
              style="top: -1.75rem; left: -1rem; font-size: 12px"
            >
              <label for="fleetUploadDocVisibility">Documento Público</label>
            </span>
            <InputSwitch
              v-if="can('sendFleetDocsToEmail')"
              v-model="docPublic"
              :name="'fleetUploadDocVisibility'"
              :id="'fleetUploadDocVisibility'"
              style="'top: 3px;"
            />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <span
              class="p-float-label"
              style="top: -1.75rem; left: -1rem; font-size: 12px"
            >
              <label for="fleetSendDocToEmail">Enviar por Email</label>
            </span>
            <InputSwitch
              v-model="sendEmail"
              :name="'fleetSendDocToEmail'"
              :id="'fleetSendDocToEmail'"
              style="'top: 3px;"
            />
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label">
              <Textarea
                v-model="comments"
                :autoResize="true"
                rows="5"
                v-bind:class="['form-control']"
                :id="'comments'"
                :name="'comments'"
              />
              <label for="comments">Comentários</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <FileUpload
              ref="fleetUploadInput"
              name="fleetUploadInput.files[]"
              id="fleetUploadInput.files"
              chooseLabel="Adicionar Ficheiro"
              :previewWidth="100"
              :multiple="false"
              :showUploadButton="false"
              :showCancelButton="false"
            />
            <small v-if="fileError" class="p-error" role="alert">
              Documento é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="save"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import "vue-loading-overlay/dist/vue-loading.css";
import fleetService from "../../services/fleet.service";
export default {
  name: "fleetAddFiles",
  props: ["show", "origin", "originId", "title", "vehicleId"],
  components: {},
  watch: {
    show() {
      if (this.show === true) {
        this.comments = null;
        this.docType = null;
        this.docPublic = true;
        this.fileError = false;
        this.sendEmail = false;
        this.$validator.resume();
      }
    },
  },
  data() {
    return {
      loading: false,
      comments: null,
      docType: null,
      docPublic: true,
      fileError: false,
      sendEmail: false,
    };
  },
  async created() {
    this.loading = false;
  },
  methods: {
    cancel() {
      this.$validator.pause();
      this.comments = null;
      this.docType = null;
      this.docPublic = true;
      this.fileError = false;
      this.sendEmail = false;
      return this.$emit("cancel");
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        this.fileError =
          this.$refs.fleetUploadInput.files.length == 0 ? true : false;

        if (!isValid || this.fileError) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        let formData = new FormData();
        formData.append("file", this.$refs.fleetUploadInput.files[0]);
        formData.append("origin", this.origin);
        formData.append("origin_id", this.originId);
        formData.append("document_type", this.docType);
        formData.append("visible_all", this.docPublic ? 1 : 0);
        formData.append("send_email", this.sendEmail ? 1 : 0);
        if (this.comments) {
          formData.append("comments", this.comments);
        }
        return fleetService
          .addDocument(this.vehicleId, formData)
          .then((response) => {
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: "Erro",
                detail: "Ocorreu um erro ao inserir o documento",
                life: 3000,
              });
            }

            this.$toast.add({
              severity: "success",
              summary: "Documento inserido",
              detail: "O Documento inserido com sucesso",
              life: 3000,
            });

            return this.$emit("fileInserted", {
              origin: this.origin,
              origin_id: this.originId,
              document: response
            });
          });
      });
    },
  },
};
</script>
