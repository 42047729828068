<template>
  <div v-if="vehicle" class="p-grid p-col-12 p-lg-12">
    <ConfirmDialog />
    <Loading :active.sync="loading" :isFullPage="true"></Loading>
    <InsuranceForm
      :show="insuranceModel.show"
      :insurance="insuranceModel.insurance"
      :vehicleId="insuranceModel.vehicleId"
      v-on:cancel="insuranceFormCancel"
      v-on:insuranceUpdateOk="insuranceUpdateOk"
      v-on:insuranceCreatedOk="insuranceCreatedOk"
    />
    <ChangeConductorForm
      :show="changeConductor"
      :vehicle="vehicle"
      v-on:cancel="changeConductor = false"
      v-on:newConductor="setNewConductor"
    />
    <AddFile
      :show="fileModel.show"
      :origin="fileModel.origin"
      :originId="fileModel.originId"
      :vehicleId="vehicle.id"
      :title="fileModel.title"
      v-on:cancel="addFileCanceled"
      v-on:fileInserted="fileInserted"
    />
    <DeleteFile
      :show="removeFileModel.show"
      :origin="removeFileModel.origin"
      :docId="removeFileModel.docId"
      :vehicleId="removeFileModel.vehicleId"
      :title="removeFileModel.title"
      :doc="removeFileModel.doc"
      v-on:cancel="removeFileCanceled"
      v-on:fileDeleted="fileDeleted"
    />
    <SendFile
      :show="sendFileModel.show"
      :origin="sendFileModel.origin"
      :docId="sendFileModel.docId"
      :vehicleId="sendFileModel.vehicleId"
      :title="sendFileModel.title"
      :doc="sendFileModel.doc"
      :conductor="sendFileModel.conductor"
      v-on:cancel="sendDocToEmailEnd"
      v-on:fileSended="sendDocToEmailEnd"
    />
    <AddNote
      :show="noteAddModel.show"
      :origin="noteAddModel.origin"
      :vehicleId="noteAddModel.vehicleId"
      :title="noteAddModel.title"
      v-on:cancel="cancelAddVehicleNote"
      v-on:addedNote="addedVehicleNote"
    />
    <DeleteNote
      :show="noteRemoveModel.show"
      :origin="noteRemoveModel.origin"
      :noteId="noteRemoveModel.noteId"
      :vehicleId="noteRemoveModel.vehicleId"
      :title="noteRemoveModel.title"
      v-on:cancel="cancelRemoveVehicleNote"
      v-on:noteDeleted="removedVehicleNote"
    />
    <KmsForm
      :show="showAddKm"
      :origin="'manual'"
      :vehicle="vehicle"
      v-on:cancel="showAddKm = false"
      v-on:addedKm="updateKm"
    />
    <FineForm
      :show="fineModel.show"
      :vehicleId="fineModel.vehicleId"
      :fineId="fineModel.fineId"
      :title="fineModel.title"
      v-on:cancel="cancelledFine"
      v-on:savedFine="savedFine"
    />
    <ReportForm
      :show="reportModel.show"
      :vehicleId="reportModel.vehicleId"
      :reportId="reportModel.reportId"
      :title="reportModel.title"
      v-on:cancel="cancelledReport"
      v-on:savedReport="savedReport"
    />
    <div class="p-grid p-col-12 p-lg-12" :style="{ position: 'relative' }">
      <div class="p-col-12 card p-shadow-6">
        <h2>
          Veículo {{ vehicle.registration }} | {{ vehicle.brand }}
          {{ vehicle.model }} | {{ vehicle.color }}
        </h2>
      </div>
    </div>
    <div class="p-col-12 p-md-6">
      <div class="card p-shadow-6">
        <Accordion :activeIndex="0">
          <AccordionTab class="accordion-custom">
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-car p-mr-2"></i>Informação do Veículo
              </div>
            </template>
            <div class="p-d-flex p-jc-end">
              <Button
                v-if="!editVehicleInfo && can('updateVehicle')"
                v-tooltip="'Editar Informação do Veículo'"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-sm"
                @click="editVehicleInfo = true"
              />
            </div>
            <form
              name="VehicleForm"
              class="p-col-12 p-mt-3"
              @submit.prevent="updateVehicle"
            >
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-3">
                  <span class="p-float-label">
                    <InputMask
                      :disabled="!editVehicleInfo"
                      v-model="vehicle.registration"
                      mask="**-**-**"
                      class="p-column-filter"
                      v-validate="'required'"
                      v-bind:class="[
                        {
                          'p-invalid': errors.has('registration'),
                        },
                        'form-control',
                      ]"
                      :name="'registration'"
                      :id="'registration'"
                    />
                    <label for="registration">Matrícula</label>
                  </span>
                  <small
                    v-if="errors.has('registration')"
                    class="p-error"
                    role="alert"
                  >
                    Matrícula é obrigatório
                  </small>
                </div>

                <div class="p-field p-col-12 p-md-3">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-calendar"></i>
                    </span>
                    <span class="p-float-label">
                      <v-date-picker
                        :name="'registration_date_picker'"
                        :id="'registration_date_picker'"
                        :disabled="!editVehicleInfo"
                        v-model="vehicle.registration_date"
                        v-validate="'required'"
                        :max-date="new Date()"
                        :masks="{
                          input: 'YYYY-MM-DD',
                        }"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <span class="p-float-label">
                            <input
                              :disabled="!editVehicleInfo"
                              :name="'registration_date'"
                              :id="'registration_date'"
                              v-bind:class="[
                                {
                                  'p-error': errors.has('registration_date'),
                                },
                                'form-control',
                                'p-inputtext',
                                'p-component',
                                'p-filled',
                              ]"
                              :value="inputValue"
                              v-on="editVehicleInfo ? inputEvents : null"
                              v-validate="'required'"
                            />
                            <label for="registration_date"
                              >Data da Matrícula</label
                            >
                          </span>
                        </template>
                      </v-date-picker>
                    </span>
                  </div>
                  <small
                    v-if="errors.has('registration_date')"
                    class="p-error p-ml-5"
                    role="alert"
                  >
                    Data da Matrícula é obrigatório
                  </small>
                </div>
                <div class="p-field p-col-12 p-md-3">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-calendar"></i>
                    </span>
                    <span class="p-float-label">
                      <v-date-picker
                        :name="'acquisition_date_picker'"
                        :id="'acquisition_date_picker'"
                        :disabled="!editVehicleInfo"
                        v-model="vehicle.acquisition_date"
                        v-validate="'required'"
                        :max-date="new Date()"
                        :masks="{
                          input: 'YYYY-MM-DD',
                        }"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <span class="p-float-label">
                            <input
                              :disabled="!editVehicleInfo"
                              :name="'acquisition_date'"
                              :id="'acquisition_date'"
                              v-bind:class="[
                                {
                                  'p-error': errors.has('acquisition_date'),
                                },
                                'form-control',
                                'p-inputtext',
                                'p-component',
                                'p-filled',
                              ]"
                              :value="inputValue"
                              v-on="editVehicleInfo ? inputEvents : null"
                              v-validate="'required'"
                            />
                            <label for="acquisition_date"
                              >Data de Aquisição</label
                            >
                          </span>
                        </template>
                      </v-date-picker>
                    </span>
                  </div>
                  <small
                    v-if="errors.has('acquisition_date')"
                    class="p-error"
                    role="alert"
                  >
                    Data de Aquisição é obrigatório
                  </small>
                </div>
                <div class="p-field p-col-12 p-md-3">
                  <span class="p-float-label">
                    <Dropdown
                      :disabled="!editVehicleInfo"
                      v-model="vehicle.acquisition_type"
                      :options="acquisitionTypesList"
                      v-validate="'required'"
                      v-bind:class="[
                        {
                          'p-invalid': errors.has('acquisition_type'),
                        },
                        'form-control',
                      ]"
                      :name="'acquisition_type'"
                      :id="'acquisition_type'"
                    />
                    <label for="acquisition_type">Tipo de Aquisição</label>
                  </span>
                  <small
                    v-if="errors.has('acquisition_type')"
                    class="p-error"
                    role="alert"
                  >
                    Tipo de Aquisição é obrigatório
                  </small>
                </div>
                <div class="p-field p-col-12 p-md-3 p-mt-2">
                  <span class="p-float-label">
                    <AutoComplete
                      :disabled="!editVehicleInfo"
                      :suggestions="filteredBrands"
                      @complete="searchBrand($event)"
                      v-model="vehicle.brand"
                      v-validate="'required'"
                      v-bind:class="[
                        {
                          'p-invalid': errors.has('brand'),
                        },
                        'form-control',
                      ]"
                      :id="'brand'"
                      :name="'brand'"
                    />
                    <label for="brand">Marca</label>
                  </span>
                  <small
                    v-if="errors.has('brand')"
                    class="p-error"
                    role="alert"
                  >
                    Marca é obrigatório
                  </small>
                </div>
                <div class="p-field p-col-12 p-md-3 p-mt-2">
                  <span class="p-float-label">
                    <AutoComplete
                      :disabled="!editVehicleInfo"
                      :suggestions="filteredModels"
                      @complete="searchModels($event)"
                      v-model="vehicle.model"
                      v-validate="'required'"
                      v-bind:class="[
                        {
                          'p-invalid': errors.has('model'),
                        },
                        'form-control',
                      ]"
                      :id="'model'"
                      :name="'model'"
                    />
                    <label for="model">Modelo</label>
                  </span>
                  <small
                    v-if="errors.has('model')"
                    class="p-error"
                    role="alert"
                  >
                    Modelo é obrigatório
                  </small>
                </div>
                <div class="p-field p-col-12 p-md-3 p-mt-2">
                  <span class="p-float-label">
                    <AutoComplete
                      :disabled="!editVehicleInfo"
                      :suggestions="filteredColors"
                      @complete="searchColors($event)"
                      v-model="vehicle.color"
                      v-validate="'required'"
                      v-bind:class="[
                        {
                          'p-invalid': errors.has('color'),
                        },
                        'form-control',
                      ]"
                      :id="'color'"
                      :name="'color'"
                    />
                    <label for="color">Cor</label>
                  </span>
                  <small
                    v-if="errors.has('color')"
                    class="p-error"
                    role="alert"
                  >
                    Cor é obrigatório
                  </small>
                </div>
                <div class="p-field p-col-12 p-md-3 p-mt-2">
                  <span class="p-float-label">
                    <Dropdown
                      :disabled="!editVehicleInfo"
                      v-model="vehicle.office"
                      :options="offices"
                      v-validate="'required'"
                      v-bind:class="[
                        {
                          'p-invalid': errors.has('office'),
                        },
                        'form-control',
                      ]"
                      :name="'office'"
                      :id="'office'"
                    />
                    <label for="office">Escritório</label>
                  </span>
                  <small
                    v-if="errors.has('office')"
                    class="p-error"
                    role="alert"
                  >
                    Escritório é obrigatório
                  </small>
                </div>
                <div class="p-field p-col-12 p-md-3 p-mt-2">
                  <span class="p-float-label">
                    <Dropdown
                      :disabled="!editVehicleInfo"
                      v-model="vehicle.fuel"
                      :options="fuelList"
                      v-validate="'required'"
                      v-bind:class="[
                        {
                          'p-invalid': errors.has('fuel'),
                        },
                        'form-control',
                      ]"
                      :name="'fuel'"
                      :id="'fuel'"
                    />
                    <label for="fuel">Combustivel</label>
                  </span>
                  <small v-if="errors.has('fuel')" class="p-error" role="alert">
                    Combustivel é obrigatório
                  </small>
                </div>
                <div class="p-field p-col-12 p-md-3 p-mt-2">
                  <span class="p-float-label">
                    <InputText
                      :disabled="!editVehicleInfo"
                      type="text"
                      v-model="vehicle.green_way"
                      v-bind:class="[
                        {
                          'p-invalid': errors.has('green_way'),
                        },
                        'form-control',
                      ]"
                      :id="'green_way'"
                      :name="'green_way'"
                    />
                    <label for="green_way">Nº Via Verde</label>
                  </span>
                </div>
                <div class="p-field p-col-12 p-md-3 p-mt-2">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-calendar"></i>
                    </span>
                    <span class="p-float-label">
                      <v-date-picker
                        :name="'inspection_date_picker'"
                        :id="'inspection_date_picker'"
                        :disabled="!editVehicleInfo"
                        v-model="vehicle.inspection_date"
                        v-validate="'required'"
                        :masks="{
                          input: 'YYYY-MM-DD',
                        }"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <span class="p-float-label">
                            <input
                              :disabled="!editVehicleInfo"
                              :name="'inspection_date'"
                              :id="'inspection_date'"
                              v-bind:class="[
                                {
                                  'p-error': errors.has('inspection_date'),
                                },
                                'form-control',
                                'p-inputtext',
                                'p-component',
                                'p-filled',
                              ]"
                              :value="inputValue"
                              v-on="editVehicleInfo ? inputEvents : null"
                              v-validate="'required'"
                            />
                            <label for="inspection_date"
                              >Data de Inspeção</label
                            >
                          </span>
                        </template>
                      </v-date-picker>
                    </span>
                  </div>
                  <small
                    v-if="errors.has('inspection_date')"
                    class="p-error"
                    role="alert"
                  >
                    Data de Inspeção é obrigatório
                  </small>
                </div>
                <div class="p-field p-col-12 p-md-3 p-mt-2">
                  <span class="p-float-label">
                    <InputNumber
                      :disabled="!editVehicleInfo"
                      v-model="vehicle.km_next_revision"
                      v-bind:class="[
                        {
                          'p-invalid': errors.has('km_next_revision'),
                        },
                        'form-control',
                      ]"
                      :id="'km_next_revision'"
                      :name="'km_next_revision'"
                    />
                    <label for="km_next_revision">Próxima Revisão (Km's)</label>
                  </span>
                </div>
                <div class="p-field p-col-12 p-md-3 p-mt-2">
                  <div class="p-inputgroup">
                    <span class="p-float-label">
                      <InputNumber
                        :disabled="true"
                        :value="
                          vehicle.current_kms ? vehicle.current_kms.km : 0
                        "
                        v-bind:class="[
                          {
                            'p-invalid': errors.has('kms'),
                          },
                          'form-control',
                        ]"
                        :id="'kms'"
                        :name="'kms'"
                      />
                      <Button
                        v-if="can('vehicleAddManualKms')"
                        icon="pi pi-plus"
                        class="p-button-success"
                        @click="showAddKm = true"
                      />
                      <label for="kms">Km's</label>
                    </span>
                  </div>
                </div>
                <div class="p-field p-col-12 p-md-3 p-mt-2">
                  <span class="p-float-label">
                    <InputText
                      :disabled="!editVehicleInfo"
                      type="text"
                      v-model="vehicle.vin"
                      v-bind:class="[
                        {
                          'p-invalid': errors.has('vin'),
                        },
                        'form-control',
                      ]"
                      :id="'vin'"
                      :name="'vin'"
                    />
                    <label for="vin">VIN</label>
                  </span>
                </div>
                <div class="p-field p-col-12 p-md-3 p-mt-2">
                  <span class="p-float-label">
                    <AutoComplete
                      :disabled="!editVehicleInfo"
                      :suggestions="filteredEntity"
                      @complete="searchEntity($event)"
                      v-model="vehicle.fleet_card_entity"
                      v-bind:class="[
                        {
                          'p-invalid': errors.has('fleet_card_entity'),
                        },
                        'form-control',
                      ]"
                      :id="'fleet_card_entity'"
                      :name="'fleet_card_entity'"
                    />
                    <label for="fleet_card_entity">Entidade Cartão Frota</label>
                  </span>
                </div>
                <div class="p-field p-col-12 p-md-3 p-mt-2">
                  <span class="p-float-label">
                    <InputText
                      :disabled="!editVehicleInfo"
                      type="text"
                      v-model="vehicle.fleet_card_number"
                      v-bind:class="[
                        {
                          'p-invalid': errors.has('fleet_card_number'),
                        },
                        'form-control',
                      ]"
                      :id="'fleet_card_number'"
                      :name="'fleet_card_number'"
                    />
                    <label for="fleet_card_number">Nº Cartão Frota</label>
                  </span>
                </div>
                <div class="p-field p-col-12 p-md-2 p-mt-2">
                  <span
                    class="p-float-label"
                    style="top: -0.75rem; left: -1rem; font-size: 12px"
                  >
                    <label for="vehicle-active">Veículo Ativo</label>
                  </span>
                  <InputSwitch
                    :disabled="!editVehicleInfo"
                    v-model="vehicle.active"
                    :name="'vehicle-active'"
                    :id="'vehicle-active'"
                    style="'top: 4px;"
                  />
                </div>
                <div class="p-field p-col-12 p-md-12 p-mt-2">
                  <span class="p-float-label">
                    <Textarea
                      :disabled="!editVehicleInfo"
                      v-model="vehicle.comments"
                      :autoResize="true"
                      rows="5"
                      v-bind:class="['form-control']"
                      :id="'comments'"
                      :name="'comments'"
                    />
                    <label for="comments">Comentários</label>
                  </span>
                </div>
                <div class="p-col-12 p-md-12 p-mt-2"></div>
              </div>
              <div
                class="p-field p-col-12 p-d-flex p-jc-end"
                v-if="editVehicleInfo"
              >
                <Button
                  label="Gravar"
                  icon="pi pi-save"
                  class="p-button-success"
                  @click="updateVehicle"
                />
                <Button
                  label="Cancelar"
                  icon="pi pi-times"
                  class="p-button-danger p-ml-2"
                  @click="editVehicleInfo = false"
                />
              </div>
            </form>
            <DataTable
              v-if="can('seeVehicleDocs')"
              :value="vehicle.documents"
              :filters.sync="filtersVehicle"
              filterDisplay="row"
              :loading="loading"
              class="p-datatable-sm"
              :sortOrder="1"
              responsiveLayout="scroll"
              removableSort
              sortMode="single"
              :autoLayout="true"
            >
              <template #header>
                <div class="table-header">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    <span class="table-title">Veículo Documentos</span>
                    <Button
                      v-if="can('uploadVehicleDocs')"
                      v-tooltip="'Adicionar Ficheiro ao Veículo'"
                      icon="pi pi-upload"
                      class="p-button-rounded p-button-sm p-button-success p-mt-2"
                      @click="addVehicleFile()"
                    />
                  </div>
                </div>
              </template>
              <template #empty> Não existem Documentos. </template>
              <template #loading> A carregar. Por favor Aguarde... </template>
              <Column
                field="document_type"
                filterField="document_type"
                header="Tipo"
                sortable
                filterMatchMode="contains"
              >
                <template #filter="{ filterModel, filterCallback }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    @input="filterCallback()"
                  />
                </template>
              </Column>
              <Column
                field="file_origin_name"
                filterField="file_origin_name"
                header="Ficheiro"
                sortable
                filterMatchMode="contains"
              >
                <template #body="slotProps">
                  <span
                    class="clickable-item"
                    @click="downloadFile(slotProps.data)"
                    >{{ slotProps.data.file_origin_name }}</span
                  >
                </template>
                <template #filter="{ filterModel, filterCallback }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    @input="filterCallback()"
                  />
                </template>
              </Column>
              <Column
                field="comment"
                filterField="comment"
                header="Comentários"
                sortable
                filterMatchMode="contains"
              >
                <template #filter="{ filterModel, filterCallback }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    @input="filterCallback()"
                  />
                </template>
              </Column>
              <Column
                field="visible_all"
                filterField="visible_all"
                header="Público"
                sortable
                dataType="boolean"
              >
                <template #body="slotProps">
                  {{ slotProps.data.visible_all == 1 ? "Sim" : "Não" }}
                </template>
              </Column>
              <Column
                field="created_at"
                filterField="created_at"
                header="Data"
                sortable
              >
              </Column>
              <Column>
                <template #body="slotProps">
                  <Button
                    v-if="can('sendFleetDocsToEmail')"
                    icon="pi pi-send"
                    class="p-button-rounded p-button-text"
                    tooltip="Enviar Por Email ao Condutor"
                    @click="sendDocToEmail(slotProps.data, 'vehicle')"
                  />
                  <Button
                    v-if="can('deleteVehicleDocs')"
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-danger p-button-text"
                    tooltip="Remover Documento"
                    @click="removeVehicleDocument(slotProps.data)"
                  />
                </template>
              </Column>
            </DataTable>
          </AccordionTab>
        </Accordion>
      </div>
      <div class="card p-shadow-6">
        <Accordion :activeIndex="0">
          <AccordionTab class="accordion-custom">
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-book p-mr-2"></i>Seguros
              </div>
            </template>
            <div class="p-d-flex p-jc-end">
              <Button
                v-if="can('addFleetInsurances')"
                v-tooltip="'Adicionar Seguro'"
                icon="pi pi-plus"
                class="p-button-rounded p-button-success p-button-sm"
                @click="addInsurance"
              />
            </div>
            <Button
              v-if="showAllInsurance && vehicleInsurances.length > 1"
              label="Ocultar Histórico Seguros"
              icon="pi pi-eye-slash"
              class="p-button-secondary p-button-sm"
              @click="hideAllInsurances"
            />
            <div
              class="p-fluid p-grid p-mt-5"
              v-for="(insurance, index) in vehicleInsurances"
              :key="insurance.id"
            >
              <div class="p-col-11 p-grid">
                <div class="p-field p-col-12 p-md-3">
                  <span class="p-float-label">
                    <InputText
                      :disabled="true"
                      type="text"
                      v-model="insurance.start"
                      v-bind:class="['form-control']"
                      :id="'current_insurance_start'"
                      :name="'current_insurance_start'"
                    />
                    <label for="current_insurance_start">Data de Inicío</label>
                  </span>
                </div>
                <div class="p-field p-col-12 p-md-3">
                  <span class="p-float-label">
                    <InputText
                      :disabled="true"
                      type="text"
                      v-model="insurance.end"
                      v-bind:class="['form-control']"
                      :id="'current_insurance_end'"
                      :name="'current_insurance_end'"
                    />
                    <label for="current_insurance_end">Data de Fim</label>
                  </span>
                </div>

                <div class="p-field p-col-12 p-md-3">
                  <span class="p-float-label">
                    <InputText
                      :disabled="true"
                      type="text"
                      v-model="insurance.duration"
                      v-bind:class="['form-control']"
                      :id="'current_insurance_duration'"
                      :name="'current_insurance_duration'"
                    />
                    <label for="current_insurance_duration">Duração</label>
                  </span>
                </div>

                <div class="p-field p-col-12 p-md-3">
                  <span class="p-float-label">
                    <InputText
                      :disabled="true"
                      type="text"
                      v-model="insurance.insurer"
                      v-bind:class="['form-control']"
                      :id="'current_insurance_insurer'"
                      :name="'current_insurance_insurer'"
                    />
                    <label for="current_insurance_insurer">Seguradora</label>
                  </span>
                </div>

                <div class="p-field p-col-12 p-md-4">
                  <span class="p-float-label">
                    <InputText
                      :disabled="true"
                      type="text"
                      v-model="insurance.insurance"
                      v-bind:class="['form-control']"
                      :id="'current_insurance_insurance'"
                      :name="'current_insurance_insurance'"
                    />
                    <label for="current_insurance_insurance">Apólice</label>
                  </span>
                </div>

                <div class="p-field p-col-12 p-md-2">
                  <span class="p-float-label">
                    <InputNumber
                      mode="currency"
                      currency="EUR"
                      locale="pt-PT"
                      :disabled="true"
                      type="text"
                      v-model="insurance.value"
                      v-bind:class="['form-control']"
                      :id="'current_insurance_value'"
                      :name="'current_insurance_value'"
                    />
                    <label for="current_insurance_value">Valor</label>
                  </span>
                </div>
                <div class="p-field p-col-12 p-md-4">
                  <span class="p-float-label">
                    <InputText
                      :disabled="true"
                      type="text"
                      v-model="insurance.type"
                      v-bind:class="['form-control']"
                      :id="'current_insurance_type'"
                      :name="'current_insurance_type'"
                    />
                    <label for="current_insurance_type">Tipo</label>
                  </span>
                </div>
                <div class="p-field p-col-12 p-md-2">
                  <span class="p-float-label">
                    <InputNumber
                      mode="currency"
                      currency="EUR"
                      locale="pt-PT"
                      :disabled="true"
                      type="text"
                      v-model="insurance.frank"
                      v-bind:class="['form-control']"
                      :id="'current_insurance_frank'"
                      :name="'current_insurance_frank'"
                    />
                    <label for="current_insurance_frank">Franquia</label>
                  </span>
                </div>
                <div class="p-field p-col-12 p-md-12">
                  <span class="p-float-label">
                    <Textarea
                      :disabled="true"
                      v-model="insurance.comments"
                      :autoResize="true"
                      rows="5"
                      v-bind:class="['form-controls']"
                      :id="'current_insurance_comments'"
                      :name="'current_insurance_comments'"
                    />
                    <label for="current_insurance_comments">Comentários</label>
                  </span>
                </div>
              </div>
              <div class="p-col-1 p-d-flex p-ai-center p-flex-column">
                <Button
                  v-if="can('updateFleetInsurances')"
                  v-tooltip="'Editar seguro'"
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-sm"
                  @click="editInsurance(insurance, index)"
                />
              </div>
              <DataTable
                v-if="can('seeFleetInsurancesDocs')"
                :value="insurance.documents"
                :filters.sync="filtersInsurance"
                filterDisplay="row"
                :loading="loading"
                class="p-datatable-sm"
                :sortOrder="1"
                responsiveLayout="scroll"
                removableSort
                sortMode="single"
                :autoLayout="true"
              >
                <template #header>
                  <div class="table-header">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      <span class="table-title">Seguro Documentos</span>
                      <Button
                        v-if="can('uploadInsuranceDocuments')"
                        v-tooltip="'Adicionar Ficheiro ao Seguro'"
                        icon="pi pi-upload"
                        class="p-button-rounded p-button-sm p-button-success p-mt-2"
                        @click="addInsuranceFile(insurance, index)"
                      />
                    </div>
                  </div>
                </template>
                <template #empty> Não existem Documentos. </template>
                <template #loading> A carregar. Por favor Aguarde... </template>
                <Column
                  field="document_type"
                  filterField="document_type"
                  header="Tipo"
                  sortable
                  filterMatchMode="contains"
                >
                  <template #filter="{ filterModel, filterCallback }">
                    <InputText
                      type="text"
                      v-model="filterModel.value"
                      class="p-column-filter"
                      @input="filterCallback()"
                    />
                  </template>
                </Column>
                <Column
                  field="file_origin_name"
                  filterField="file_origin_name"
                  header="Ficheiro"
                  sortable
                  filterMatchMode="contains"
                >
                  <template #body="slotProps">
                    <span
                      class="clickable-item"
                      @click="downloadFile(slotProps.data)"
                      >{{ slotProps.data.file_origin_name }}</span
                    >
                  </template>
                  <template #filter="{ filterModel, filterCallback }">
                    <InputText
                      type="text"
                      v-model="filterModel.value"
                      class="p-column-filter"
                      @input="filterCallback()"
                    />
                  </template>
                </Column>
                <Column
                  field="comment"
                  filterField="comment"
                  header="Comentários"
                  sortable
                  filterMatchMode="contains"
                >
                  <template #filter="{ filterModel, filterCallback }">
                    <InputText
                      type="text"
                      v-model="filterModel.value"
                      class="p-column-filter"
                      @input="filterCallback()"
                    />
                  </template>
                </Column>
                <Column
                  field="visible_all"
                  filterField="visible_all"
                  header="Público"
                  sortable
                  dataType="boolean"
                >
                  <template #body="slotProps">
                    {{ slotProps.data.visible_all == 1 ? "Sim" : "Não" }}
                  </template>
                </Column>
                <Column
                  field="created_at"
                  filterField="created_at"
                  header="Data"
                  sortable
                >
                </Column>
                <Column>
                  <template #body="slotProps">
                    <Button
                      v-if="can('sendFleetDocsToEmail')"
                      icon="pi pi-send"
                      class="p-button-rounded p-button-text"
                      tooltip="Enviar Por Email ao Condutor"
                      @click="sendDocToEmail(slotProps.data, 'insurance')"
                    />
                    <Button
                      v-if="can('deleteFleetInsurancesDocs')"
                      icon="pi pi-trash"
                      class="p-button-rounded p-button-danger p-button-text"
                      tooltip="Remover Documento"
                      @click="
                        removeInsurceDocument(slotProps.data, insurance, index)
                      "
                    />
                  </template>
                </Column>
              </DataTable>

              <divider v-if="vehicleInsurances.length > 1" class="myDivider" />
            </div>
            <Button
              v-if="!showAllInsurance && can('seeFleetInsurancesDocsHistory')"
              v-tooltip="'Ver Histórico de Seguros'"
              label="Ver Mais Seguros"
              icon="pi pi-history"
              class="p-button-secondary p-button-sm"
              @click="viewAllInsurances"
            />
            <Button
              v-if="
                showAllInsurance &&
                vehicleInsurances.length > 1 &&
                can('seeFleetInsurancesDocsHistory')
              "
              label="Ocultar Histórico Seguros"
              icon="pi pi-eye-slash"
              class="p-button-secondary p-button-sm"
              @click="hideAllInsurances"
            />
          </AccordionTab>
        </Accordion>
      </div>
    </div>
    <div class="p-col-12 p-md-6">
      <div class="card p-shadow-6">
        <Accordion :activeIndex="0">
          <AccordionTab class="accordion-custom">
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-users p-mr-2"></i>Utilização do Veículo
              </div>
            </template>
            <div class="p-d-flex p-jc-end">
              <Button
                v-if="can('vehiclesChangeConductor')"
                v-tooltip.left="'Trocar Condutor'"
                icon="pi pi-arrow-right-arrow-left"
                class="p-button-rounded p-button-sm"
                @click="changeConductor = true"
              />
            </div>
            <div class="p-fluid p-grid p-mt-5" v-if="vehicle.current_condutor">
              <h5>Condutor Atual</h5>
              <div class="p-col-12 p-grid">
                <div class="p-field p-col-12 p-md-6">
                  <span class="p-float-label">
                    <InputText
                      :disabled="true"
                      type="text"
                      v-model="vehicle.current_condutor.conductor.username"
                      v-bind:class="[
                        {
                          'p-invalid': errors.has('current_conductor_name'),
                        },
                        'form-control',
                      ]"
                      :id="'current_conductor_name'"
                      :name="'current_conductor_name'"
                    />
                    <label for="current_conductor_name">Nome</label>
                  </span>
                </div>
                <div class="p-field p-col-12 p-md-6">
                  <span class="p-float-label">
                    <InputText
                      :disabled="true"
                      type="text"
                      v-model="vehicle.current_condutor.start"
                      v-bind:class="[
                        {
                          'p-invalid': errors.has('current_conductor_start'),
                        },
                        'form-control',
                      ]"
                      :id="'current_conductor_start'"
                      :name="'current_conductor_start'"
                    />
                    <label for="current_conductor_start"
                      >Inicío da Utilização</label
                    >
                  </span>
                </div>
              </div>
            </div>
            <div class="p-fluid p-grid p-mt-5" v-else>
              <h5>Veículo sem Condutor</h5>
            </div>
            <Button
              v-if="!showAllUse && can('seeVehicleUseHistory')"
              label="Ver Histórico de Utilização"
              icon="pi pi-history"
              class="p-button-secondary p-button-sm"
              @click="viewAllUse"
            />
            <Button
              v-if="showAllUse"
              label="Ocultar Histórico de Utilização"
              icon="pi pi-eye-slash"
              class="p-button-secondary p-button-sm p-mb-2"
              @click="hideAllUse"
            />
            <div v-if="allUse.length > 0">
              <DataTable
                :value="allUse"
                :filters.sync="filters"
                filterDisplay="row"
                :loading="loading"
                class="p-datatable-sm"
                :sortOrder="1"
                responsiveLayout="scroll"
                removableSort
                sortMode="single"
                :scrollable="true"
                scrollHeight="flex"
                :autoLayout="true"
                :paginator="true"
                :rows="15"
              >
                <template #header>
                  <div class="table-header">
                    <h5>Histórico de Utilização</h5>
                  </div>
                </template>
                <template #empty> Não existem Veículos. </template>
                <template #loading> A carregar. Por favor Aguarde... </template>
                <Column
                  field="startDate"
                  filterField="startDate"
                  header="Inicío"
                  sortable
                  filterMatchMode="contains"
                  dataType="date"
                >
                  <template #body="slotProps">
                    {{ slotProps.data.start }}
                  </template>
                  <template #filter="{ filterModel, filterCallback }">
                    <v-date-picker
                      v-model="filterModel.value"
                      @input="filterCallback()"
                      :masks="{
                        input: 'YYYY-MM-DD',
                      }"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          class="p-column-filter p-inputtext p-component"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                  </template>
                </Column>
                <Column
                  field="endDate"
                  filterField="endDate"
                  header="Fim"
                  sortable
                  filterMatchMode="contains"
                >
                  <template #body="slotProps">
                    {{ slotProps.data.end }}
                  </template>
                  <template #filter="{ filterModel, filterCallback }">
                    <v-date-picker
                      v-model="filterModel.value"
                      @input="filterCallback()"
                      :masks="{
                        input: 'YYYY-MM-DD',
                      }"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          class="p-column-filter p-inputtext p-component"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                  </template>
                </Column>
                <Column
                  field="conductor.username"
                  filterField="conductor.username"
                  header="Condutor"
                  sortable
                  filterMatchMode="contains"
                >
                  <template #filter="{ filterModel, filterCallback }">
                    <InputText
                      type="text"
                      v-model="filterModel.value"
                      class="p-column-filter"
                      @input="filterCallback()"
                    />
                  </template>
                </Column>
                <Column
                  field="comment"
                  filterField="comment"
                  header="Comentário"
                  sortable
                  filterMatchMode="contains"
                >
                  <template #filter="{ filterModel, filterCallback }">
                    <InputText
                      type="text"
                      v-model="filterModel.value"
                      class="p-column-filter"
                      @input="filterCallback()"
                    />
                  </template>
                </Column>
              </DataTable>
              <Button
                v-if="showAllUse"
                label="Ocultar Histórico de Utilização"
                icon="pi pi-eye-slash"
                class="p-button-secondary p-button-sm p-mb-2"
                @click="hideAllUse"
              />
            </div>
          </AccordionTab>
        </Accordion>
      </div>
      <div class="card p-shadow-6" v-if="can('seeVehicleNotes')">
        <Accordion>
          <AccordionTab class="accordion-custom">
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-comment p-mr-2"></i>Notas do Veículo
              </div>
              <Badge v-badge="notes.length" class="p-ml-2"></Badge>
            </template>
            <DataTable
              :value="notes"
              :filters.sync="filtersNotes"
              filterDisplay="row"
              :loading="loading"
              class="p-datatable-sm"
              responsiveLayout="scroll"
              removableSort
              sortMode="single"
              :scrollable="true"
              scrollHeight="flex"
              :autoLayout="true"
              :paginator="true"
              :rows="15"
            >
              <template #header v-if="can('addVehicleNote')">
                <div class="table-header">
                  <div class="p-d-flex p-jc-end p-ai-center">
                    <Button
                      v-tooltip.left="'Adicionar Nota'"
                      icon="pi pi-plus"
                      class="p-button-rounded p-button-sm p-button-success p-mt-2"
                      @click="addVehicleNote()"
                    />
                  </div>
                </div>
              </template>
              <template #empty> Não existem Notas. </template>
              <template #loading> A carregar. Por favor Aguarde... </template>
              <Column
                field="note"
                filterField="note"
                header="Nota"
                filterMatchMode="contains"
                :headerStyle="{ 'min-width': '65%', 'max-width': '65%' }"
                :filterHeaderStyle="{
                  'min-width': '65%',
                  'max-width': '65%',
                }"
                :bodyStyle="{ 'min-width': '65%', 'max-width': '65%' }"
              >
                <template #filter="{ filterModel, filterCallback }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    @input="filterCallback()"
                  />
                </template>
              </Column>
              <Column
                field="user_created.username"
                filterField="user_created.username"
                header="Autor"
                filterMatchMode="contains"
                :headerStyle="{ 'min-width': '25%', 'max-width': '25%' }"
                :filterHeaderStyle="{ 'min-width': '25%', 'max-width': '25%' }"
                :bodyStyle="{ 'min-width': '25%', 'max-width': '25%' }"
              >
                <template #body="slotProps">
                  {{ slotProps.data.user_created.username }} <br />
                  {{ slotProps.data.created_at }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    @input="filterCallback()"
                  />
                </template>
              </Column>
              <Column>
                <template #body="slotProps">
                  <Button
                    v-if="can('deleteVehicleNotes')"
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-danger p-button-text"
                    tooltip="Remover Documento"
                    @click="removeVehicleNote(slotProps.data)"
                  />
                </template>
              </Column>
            </DataTable>
          </AccordionTab>
        </Accordion>
      </div>
      <div class="card p-shadow-6" v-if="can('seeVehicleFines')">
        <Accordion>
          <AccordionTab class="accordion-custom">
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-ban p-mr-2"></i>Multas
              </div>
              <Badge v-badge="fines.length" class="p-ml-2"></Badge>
            </template>
            <DataTable
              :value="fines"
              :filters.sync="filtersFines"
              filterDisplay="row"
              :loading="loading"
              class="p-datatable-sm"
              responsiveLayout="scroll"
              removableSort
              sortMode="single"
              :scrollable="true"
              scrollHeight="flex"
              :autoLayout="true"
              :paginator="true"
              :rows="15"
            >
              <template #header v-if="can('addVehicleFines')">
                <div class="table-header">
                  <div class="p-d-flex p-jc-end p-ai-center">
                    <Button
                      v-tooltip.left="'Adicionar Multa'"
                      icon="pi pi-plus"
                      class="p-button-rounded p-button-sm p-button-success p-mt-2"
                      @click="addFine()"
                    />
                  </div>
                </div>
              </template>
              <template #empty> Não existem Multas. </template>
              <template #loading> A carregar. Por favor Aguarde... </template>
              <Column
                field="date"
                header="Data"
                sortable
                :headerStyle="{ 'min-width': '25%', 'max-width': '25%' }"
                :filterHeaderStyle="{ 'min-width': '25%', 'max-width': '25%' }"
                :bodyStyle="{ 'min-width': '25%', 'max-width': '25%' }"
              >
                <template #body="slotProps">
                  <span
                    class="clickable-item"
                    @click="viewEditFine(slotProps.data)"
                    >{{ slotProps.data.date }}</span
                  >
                </template>
              </Column>
              <Column
                sortable
                field="conductor.username"
                filterField="conductor.username"
                header="Condutor"
                filterMatchMode="contains"
                :headerStyle="{ 'min-width': '25%', 'max-width': '25%' }"
                :filterHeaderStyle="{ 'min-width': '25%', 'max-width': '25%' }"
                :bodyStyle="{ 'min-width': '25%', 'max-width': '25%' }"
              >
                <template #filter="{ filterModel, filterCallback }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    @input="filterCallback()"
                  />
                </template>
              </Column>
              <Column
                field="type"
                header="Tipo"
                :headerStyle="{ 'min-width': '30%', 'max-width': '30%' }"
                :filterHeaderStyle="{ 'min-width': '30%', 'max-width': '30%' }"
                :bodyStyle="{ 'min-width': '30%', 'max-width': '30%' }"
              >
              </Column>
              <Column
                sortable
                field="date_to_response"
                header="Data Resposta"
                :headerStyle="{ 'min-width': '20%', 'max-width': '20%' }"
                :filterHeaderStyle="{ 'min-width': '20%', 'max-width': '20%' }"
                :bodyStyle="{ 'min-width': '20%', 'max-width': '20%' }"
              >
              </Column>
            </DataTable>
          </AccordionTab>
        </Accordion>
      </div>

      <div class="card p-shadow-6">
        <Accordion>
          <AccordionTab class="accordion-custom">
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-verified p-mr-2"></i>Relatórios
              </div>
              <Badge v-badge="reports.length" class="p-ml-2"></Badge>
            </template>
            <DataTable
              :value="reports"
              :filters.sync="filtersReports"
              filterDisplay="row"
              :loading="loading"
              class="p-datatable-sm"
              responsiveLayout="scroll"
              removableSort
              sortMode="single"
              :scrollable="true"
              scrollHeight="flex"
              :autoLayout="true"
              :paginator="true"
              :rows="15"
            >
              <template #header>
                <div class="table-header">
                  <div class="p-d-flex p-jc-end p-ai-center">
                    <Button
                      v-tooltip.left="'Adicionar Relatório'"
                      icon="pi pi-plus"
                      class="p-button-rounded p-button-sm p-button-success p-mt-2"
                      @click="addReport()"
                    />
                  </div>
                </div>
              </template>
              <template #empty> Não existem Relatórios. </template>
              <template #loading> A carregar. Por favor Aguarde... </template>
              <Column
                field="id"
                header="Id"
                :headerStyle="{ 'min-width': '5%', 'max-width': '5%' }"
                :filterHeaderStyle="{ 'min-width': '5%', 'max-width': '5%' }"
                :bodyStyle="{ 'min-width': '5%', 'max-width': '5%' }"
              >
                <template #body="slotProps">
                  <span
                    class="clickable-item"
                    @click="viewEditReport(slotProps.data)"
                    >{{ slotProps.data.id }}</span
                  >
                </template>
              </Column>
              <Column
                field="user_created.username"
                filterField="user_created.username"
                header="Autor"
                sortable
                :headerStyle="{ 'min-width': '25%', 'max-width': '25%' }"
                :filterHeaderStyle="{ 'min-width': '25%', 'max-width': '25%' }"
                :bodyStyle="{ 'min-width': '25%', 'max-width': '25%' }"
              >
                <template #filter="{ filterModel, filterCallback }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    @input="filterCallback()"
                  />
                </template>
              </Column>
              <Column
                field="kms"
                header="Kms"
                :headerStyle="{ 'min-width': '20%', 'max-width': '20%' }"
                :filterHeaderStyle="{ 'min-width': '20%', 'max-width': '20%' }"
                :bodyStyle="{ 'min-width': '20%', 'max-width': '20%' }"
              >
              </Column>
              <Column
                field="closed"
                header="Fechado/Enviado"
                sortable
                filterField="closed"
                dataType="boolean"
                :headerStyle="{ 'min-width': '25%', 'max-width': '25%' }"
                :filterHeaderStyle="{ 'min-width': '25%', 'max-width': '25%' }"
                :bodyStyle="{ 'min-width': '25%', 'max-width': '25%' }"
              >
                <template #body="slotProps">
                  <span>{{ slotProps.data.closed == 1 ? "Sim" : "Não" }}</span>
                </template>
                <template #filter="{ filterModel, filterCallback }">
                  <Dropdown
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    :options="[
                      { value: 0, label: 'Não' },
                      { value: 1, label: 'Sim' },
                    ]"
                    optionValue="value"
                    optionLabel="label"
                    class="p-column-filter"
                    :showClear="true"
                  >
                  </Dropdown>
                </template>
              </Column>
              <Column
                field="send_date"
                header="Data Envio"
                :headerStyle="{ 'min-width': '25%', 'max-width': '25%' }"
                :filterHeaderStyle="{ 'min-width': '25%', 'max-width': '25%' }"
                :bodyStyle="{ 'min-width': '25%', 'max-width': '25%' }"
              >
              </Column>
            </DataTable>
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  </div>
</template>
<script>
import fleetService from "../services/fleet.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { getOnlyDate } from "../helpers/helpers";
import InsuranceForm from "../components/fleet/InsuranceForm.vue";
import ChangeConductorForm from "../components/fleet/ChangeConductorForm.vue";
import AddFile from "../components/fleet/AddFile.vue";
import DeleteFile from "../components/fleet/DeleteFile.vue";
import SendFile from "../components/fleet/SendFile.vue";
import AddNote from "../components/fleet/AddNote.vue";
import DeleteNote from "../components/fleet/DeleteNote.vue";
import KmsForm from "../components/fleet/KmsForm.vue";
import FineForm from "../components/fleet/FineForm.vue";
import ReportForm from "../components/fleet/ReportForm.vue";
import { FilterMatchMode } from "primevue/api/";
export default {
  name: "VehicleDetail",
  components: {
    Loading,
    InsuranceForm,
    ChangeConductorForm,
    AddFile,
    DeleteFile,
    SendFile,
    AddNote,
    DeleteNote,
    KmsForm,
    FineForm,
    ReportForm,
  },
  data() {
    return {
      loading: true,
      vehicle: null,
      editVehicleInfo: false,
      offices: [],
      fuelList: [],
      acquisitionTypesList: [],
      vehicleInsurances: [],
      notes: [],
      fines: [],
      insuranceModel: { show: false, insurance: {} },
      fileModel: { show: false, origin: null, originId: null, title: null },
      removeFileModel: {
        show: false,
        origin: null,
        docId: null,
        doc: null,
        vehicleId: null,
        title: null,
      },
      sendFileModel: {
        show: false,
        origin: null,
        docId: null,
        doc: null,
        vehicleId: null,
        title: null,
      },
      showAllInsurance: false,
      showAllUse: false,
      allUse: [],
      filters: {
        startDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        endDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "conductor.username": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        comment: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filtersInsurance: {
        document_type: { value: null, matchMode: FilterMatchMode.CONTAINS },
        file_origin_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        comment: { value: null, matchMode: FilterMatchMode.CONTAINS },
        visible_all: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      filtersVehicle: {
        document_type: { value: null, matchMode: FilterMatchMode.CONTAINS },
        file_origin_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        comment: { value: null, matchMode: FilterMatchMode.CONTAINS },
        visible_all: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      filtersNotes: {
        note: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "user_created.username": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      filtersFines: {
        "conductor.username": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      changeConductor: false,
      showAddKm: false,
      noteRemoveModel: {
        show: false,
        vehicleId: null,
        noteId: null,
        origin: null,
        title: null,
      },
      noteAddModel: {
        show: false,
        vehicleId: null,
        origin: null,
        title: null,
      },
      brandsList: [],
      filteredBrands: null,
      modelsList: [],
      filteredModels: null,
      colorsList: [],
      filteredColors: null,
      entityList: [],
      filteredEntity: null,
      fineModel: {
        show: false,
        vehicleId: null,
        fineId: null,
        title: null,
      },
      reports: [],
      filtersReports: {
        "user_created.username": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        closed: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      reportModel: {
        show: false,
        vehicleId: null,
        reportId: null,
        title: null,
      },
    };
  },
  async beforeCreate() {
    this.offices = await fleetService.getOffices();
    this.fuelList = await fleetService.getFuel();
    this.acquisitionTypesList = await fleetService.getAcquisitionTypes();
    this.vehicle = await fleetService.getVehicleInfo(this.$route.params.id);
    this.vehicle.active = this.vehicle.active == 1 ? true : false;
    if (this.vehicle.current_insurance) {
      this.vehicleInsurances.push(this.vehicle.current_insurance);
    }
    if (this.can("seeVehicleNotes")) {
      this.notes = await fleetService.getVehicleNotes(this.$route.params.id);
    }
    if (this.can("seeVehicleFines")) {
      this.fines = await fleetService.getVehicleFines(this.$route.params.id);
    }
    this.reports = await fleetService.getReports(this.$route.params.id);
    this.brandsList = await fleetService.getBrands();
    this.modelsList = await fleetService.getModels();
    this.colorsList = await fleetService.getColors();
    this.entityList = await fleetService.getCardsEntity();
    this.loading = false;
  },
  methods: {
    searchBrand(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredBrands = [...this.brandsList];
        } else {
          this.filteredBrands = this.brandsList.filter((brand) => {
            return brand.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchModels(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredModels = [...this.modelsList];
        } else {
          this.filteredModels = this.modelsList.filter((model) => {
            return model.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchColors(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredColors = [...this.colorsList];
        } else {
          this.filteredColors = this.colorsList.filter((color) => {
            return color.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchEntity(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredEntity = [...this.entityList];
        } else {
          this.filteredEntity = this.entityList.filter((entity) => {
            return entity.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    updateVehicle() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        if (this.vehicle.acquisition_date instanceof Date) {
          this.vehicle.acquisition_date = getOnlyDate(
            this.vehicle.acquisition_date
          );
        }
        if (this.vehicle.registration_date instanceof Date) {
          this.vehicle.registration_date = getOnlyDate(
            this.vehicle.registration_date
          );
        }
        if (this.vehicle.inspection_date instanceof Date) {
          this.vehicle.inspection_date = getOnlyDate(
            this.vehicle.inspection_date
          );
        }
        let bodyParams = { ...this.vehicle };
        bodyParams.active = this.vehicle.active == true ? 1 : 0;

        fleetService
          .updateVehicle(this.vehicle.id, bodyParams)
          .then((response) => {
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: `Erro ao Atualizar o Veículo`,
                detail: "",
                life: 3000,
              });
            }
            this.$toast.add({
              severity: "success",
              summary: `Veículo Atualizado`,
              detail: "",
              life: 3000,
            });

            return (this.editVehicleInfo = false);
          });
      });
    },
    editInsurance(insurance, index) {
      return (this.insuranceModel = {
        show: true,
        index: index,
        insurance: { ...insurance },
      });
    },
    addInsurance() {
      return (this.insuranceModel = {
        show: true,
        insurance: {},
        vehicleId: this.vehicle.id,
      });
    },
    insuranceUpdateOk(insurance) {
      if (insurance.status == "ok") {
        this.vehicleInsurances[this.insuranceModel.index] = insurance.insurance;
      }
      return this.insuranceFormCancel();
    },
    insuranceCreatedOk(insurance) {
      if (insurance.status == "ok") {
        this.vehicleInsurances.push(insurance.insurance);
      }
      return this.insuranceFormCancel();
    },
    insuranceFormCancel() {
      return (this.insuranceModel = { show: false, insurance: {} });
    },
    viewAllInsurances() {
      this.showAllInsurance = true;
      return fleetService.getAllInsurances(this.vehicle.id).then((response) => {
        return (this.vehicleInsurances = response);
      });
    },
    hideAllInsurances() {
      this.vehicleInsurances = this.vehicle.current_insurance
        ? [this.vehicle.current_insurance]
        : [];
      return (this.showAllInsurance = false);
    },
    async viewAllUse() {
      this.showAllUse = true;
      return fleetService.getVehicleUse(this.vehicle.id).then((response) => {
        return (this.allUse = response.map((v) => ({
          ...v,
          startDate: new Date(v.start),
          endDate: new Date(v.end),
        })));
      });
    },
    hideAllUse() {
      this.allUse = [];
      return (this.showAllUse = false);
    },
    setNewConductor(conductor) {
      if (conductor.status == "ok") {
        if (this.showAllUse) {
          this.allUse[0]["end"] = conductor.currentConductor.start;
          this.allUse.unshift(conductor.currentConductor);
        }
        this.vehicle.current_condutor = conductor.currentConductor;
      }
      return (this.changeConductor = false);
    },
    downloadFile(fileInfo) {
      return fleetService
        .getDocument(fileInfo.vehicle_id, fileInfo.id)
        .then((response) => {
          let url = window.URL.createObjectURL(new Blob([response]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileInfo.file_origin_name);
          document.body.appendChild(link);
          link.click();
        });
    },
    addFileCanceled() {
      return (this.fileModel = {
        show: false,
        index: null,
        origin: null,
        originId: null,
        title: null,
      });
    },
    addInsuranceFile(insurance, index) {
      let title = `Seguro | ${insurance.insurer} | ${insurance.insurance} | ${insurance.start} - ${insurance.end}`;
      return (this.fileModel = {
        show: true,
        index: index,
        origin: "insurance",
        originId: insurance.id,
        title: title,
      });
    },
    addVehicleFile() {
      let title = `Veículo ${this.vehicle.brand} - ${this.vehicle.model} | ${this.vehicle.registration}`;
      return (this.fileModel = {
        show: true,
        index: null,
        origin: "vehicle",
        originId: this.vehicle.id,
        title: title,
      });
    },
    removeFileCanceled() {
      return (this.removeFileModel = {
        show: false,
        origin: null,
        docId: null,
        doc: null,
        vehicleId: null,
        title: null,
      });
    },
    removeInsurceDocument(doc, insurance, insuranceIndex) {
      let title = `Seguro | ${insurance.insurer} | ${insurance.insurance} | ${insurance.start} - ${insurance.end}`;
      return (this.removeFileModel = {
        show: true,
        index: insuranceIndex,
        origin: "insurance",
        docId: doc.id,
        doc: doc,
        vehicleId: this.vehicle.id,
        title: title,
      });
    },
    sendDocToEmail(doc, origin) {
      let title = `Veículo ${this.vehicle.brand} - ${this.vehicle.model} | ${this.vehicle.registration}`;
      return (this.sendFileModel = {
        show: true,
        origin: origin,
        docId: doc.id,
        doc: doc,
        vehicleId: this.vehicle.id,
        title: title,
        conductor: this.vehicle.current_condutor
          ? this.vehicle.current_condutor.conductor.username
          : "Frota",
      });
    },
    sendDocToEmailEnd() {
      return (this.sendFileModel = {
        show: false,
        origin: null,
        docId: null,
        doc: null,
        vehicleId: null,
        title: null,
        conductor: null,
      });
    },
    removeVehicleDocument(doc) {
      let title = `Veículo ${this.vehicle.brand} - ${this.vehicle.model} | ${this.vehicle.registration}`;
      return (this.removeFileModel = {
        show: true,
        origin: "vehicle",
        docId: doc.id,
        doc: doc,
        vehicleId: this.vehicle.id,
        title: title,
      });
    },
    fileDeleted(info) {
      const areYou = (file) => {
        return file.id == info.docId;
      };
      if (info.origin === "insurance") {
        let index =
          this.vehicleInsurances[this.removeFileModel.index][
            "documents"
          ].findIndex(areYou);
        if (index >= 0) {
          this.vehicleInsurances[this.removeFileModel.index][
            "documents"
          ].splice(index, 1);
        }
      } else if (info.origin === "vehicle") {
        let index = this.vehicle.documents.findIndex(areYou);
        if (index >= 0) {
          this.vehicle.documents.splice(index, 1);
        }
      }

      return this.removeFileCanceled();
    },
    fileInserted(doc) {
      if (doc.origin === "insurance") {
        this.vehicleInsurances[this.fileModel.index].documents.unshift(
          doc.document
        );
      } else if (doc.origin === "vehicle") {
        this.vehicle.documents.unshift(doc.document);
      }
      return this.addFileCanceled();
    },
    updateKm(kms) {
      if (kms.status == "ok") {
        this.vehicle.current_kms = kms.currents_kms;
      }
      return (this.showAddKm = false);
    },
    addVehicleNote() {
      let title = `Veículo ${this.vehicle.brand} - ${this.vehicle.model} | ${this.vehicle.registration}`;
      return (this.noteAddModel = {
        show: true,
        vehicleId: this.vehicle.id,
        origin: "vehicle",
        title: title,
      });
    },
    cancelAddVehicleNote() {
      return (this.noteAddModel = {
        show: false,
        vehicleId: null,
        origin: null,
        title: null,
      });
    },
    addedVehicleNote(info) {
      if (info.status == "ok") {
        this.notes.unshift(info.note);
      }
      return this.cancelAddVehicleNote();
    },
    cancelRemoveVehicleNote() {
      return (this.noteRemoveModel = {
        show: false,
        vehicleId: null,
        noteId: null,
        origin: null,
        title: null,
      });
    },
    removeVehicleNote(note) {
      let title = `Veículo ${this.vehicle.brand} - ${this.vehicle.model} | ${this.vehicle.registration}`;
      return (this.noteRemoveModel = {
        show: true,
        vehicleId: this.vehicle.id,
        noteId: note.id,
        origin: "vehicle",
        title: title,
      });
    },
    removedVehicleNote(info) {
      const areYou = (note) => {
        return note.id == info.noteId;
      };
      if (info.status == "ok") {
        let index = this.notes.findIndex(areYou);
        if (index >= 0) {
          this.notes.splice(index, 1);
        }
      }
      return this.cancelRemoveVehicleNote();
    },
    viewEditFine(fine) {
      let title = `Veículo ${this.vehicle.brand} - ${this.vehicle.model} | ${this.vehicle.registration}`;
      return (this.fineModel = {
        show: true,
        vehicleId: this.vehicle.id,
        fineId: fine.id,
        title: title,
        fine: fine,
      });
    },
    addFine() {
      let title = `Veículo ${this.vehicle.brand} - ${this.vehicle.model} | ${this.vehicle.registration}`;
      return (this.fineModel = {
        show: true,
        vehicleId: this.vehicle.id,
        fineId: null,
        title: title,
      });
    },
    async savedFine(info) {
      if (this.fineModel.fineId == null) {
        this.fines.unshift(info.fine);
      } else {
        this.fines = await fleetService.getVehicleFines(this.$route.params.id);
      }

      return this.cancelledFine();
    },
    cancelledFine() {
      return (this.fineModel = {
        show: false,
        vehicleId: null,
        fineId: null,
        title: null,
      });
    },
    addReport() {
      let title = `Veículo ${this.vehicle.brand} - ${this.vehicle.model} | ${this.vehicle.registration}`;
      return (this.reportModel = {
        show: true,
        vehicleId: this.vehicle.id,
        reportId: null,
        title: title,
      });
    },
    viewEditReport(report) {
      let title = `Veículo ${this.vehicle.brand} - ${this.vehicle.model} | ${this.vehicle.registration}`;
      return (this.reportModel = {
        show: true,
        vehicleId: this.vehicle.id,
        reportId: report.id,
        title: title,
      });
    },
    cancelledReport() {
      return (this.reportModel = {
        show: false,
        vehicleId: null,
        reportId: null,
        title: null,
      });
    },
    async savedReport(info) {
      if (this.reportModel.reportId == null) {
        this.reports.unshift(info.report);
      }
      this.reports = await fleetService.getReports(this.$route.params.id);
      return this.cancelledReport();
    },
  },
};
</script>
<style>
.myDivider {
  border-color: #474a54;
  border-bottom-style: solid;
}

.p-accordion-header-link {
  background-color: #474a54 !important;
  color: #fff !important;
}
.table-title {
  font-family: inherit;
  font-weight: normal;
  line-height: 1.2;
  color: inherit;
  font-size: 1.25rem;
}
</style>
