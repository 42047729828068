<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="dialogFleetRemoveNote" />
  </div>
</template>
<script>
import fleetService from "../../services/fleet.service";
export default {
  name: "FleetRemoveNote",
  props: ["show", "origin", "noteId", "vehicleId", "title"],
  watch: {
    show() {
      if (this.show === true) {
        this.removenote();
      }
    },
  },
  methods: {
    removenote() {
      let title = `Remover Ficheiro`;
      title += ` | ${this.title}`;
      let message = `Tem a certeza que pretende remover a nota?`;
      this.$confirm.require({
        key: "dialogFleetRemoveNote",
        header: title,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return this.removeNote();
        },
        reject: () => {
          return this.$emit("cancel");
        },
      });
    },
    removeNote() {
      return fleetService
        .deleteVehicleNote(this.vehicleId, this.noteId)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao eliminar a nota",
              life: 3000,
            });
          }
          this.$toast.add({
            severity: "success",
            summary: "Nota eliminada",
            detail: "A Nota foi eliminada com sucesso",
            life: 3000,
          });
          return this.$emit("noteDeleted", {
            status: "ok",
            origin: this.origin,
            noteId: this.noteId,
          });
        });
    },
  },
};
</script>
