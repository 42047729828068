<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '20vw' }"
      :header="`Adicionar Nota`"
      :modal="true"
      :closable="false"
    >
      <p>
        <b>{{ title }}</b>
      </p>
      <form
        name="vehicle-note-form"
        class="p-col-12"
        @submit.prevent="save"
        style="min-height: 20vh"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label">
              <Textarea
                v-model="note"
                :autoResize="true"
                rows="5"
                v-bind:class="['form-control']"
                :id="'note'"
                :name="'note'"
              />
              <label for="note">Nota</label>
            </span>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="save"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import "vue-loading-overlay/dist/vue-loading.css";
import fleetService from "../../services/fleet.service";
export default {
  name: "KmsForm",
  props: ["show", "origin", "vehicleId", "title"],
  components: {},
  watch: {
    show() {
      if (this.show === true) {
        this.$validator.resume();
        this.note = null;
      }
    },
  },
  data() {
    return {
      note: null,
    };
  },
  async created() {},
  methods: {
    cancel() {
      this.$validator.pause();
      this.note = null;
      return this.$emit("cancel");
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        let bodyParams = { note: this.note };
        this.$validator.pause();
        fleetService.addVehicleNote(this.vehicleId, bodyParams).then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: `Erro ao adicionar Nota`,
              detail: "",
              life: 3000,
            });
          }
          this.$toast.add({
            severity: "success",
            summary: `Nota adicionada com sucesso`,
            detail: "",
            life: 3000,
          });
          return this.$emit("addedNote", {
            status: "ok",
            note: response,
          });
        });
      });
    },
  },
};
</script>
